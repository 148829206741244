<template>

  <div class="row" ref="companyForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-md-6 col-sm-12">

                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="Company Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Company Name"
                            name="name"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>

                <fg-input type="phone"
                          label="Phone"
                          name="phone"
                          fou
                          v-model="formData.phone">
                </fg-input>

                <div class="form-group">
                  <label>Parent Company</label>
                  <el-select class="select-default"
                             size="large"
                             v-model="formData.parent_id"
                             filterable
                             clearable
                             placeholder="Select Parent Company">
                    <el-option v-for="option in companies"
                               class="select-default"
                               :value="option.id"
                               :label="option.name"
                               :key="option.id">
                    </el-option>
                  </el-select>
                </div>

                <fg-input type="head_office"
                          label="Head Office"
                          name="head_office"
                          fou
                          v-model="formData.head_office">
                </fg-input>

                <fg-input type="address"
                          label="Address"
                          name="address"
                          fou
                          v-model="formData.address">
                </fg-input>

                <div class="form-group">
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>&nbsp;
                  <label>Is Active</label>
                </div>

              </div>

              <div class="col-xs-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Logo</label>
                  <el-tooltip placement="right">
                    <div slot="content">{{getCompanyLogoInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <image-uploader :number-of-files="1"
                                  :input-list="logoImages"
                                  @change="logoChanged">
                  </image-uploader>
                </div>

                <div class="form-group">
                  <label>Side Bar Image</label>
                  <el-tooltip placement="right">
                    <div slot="content">{{ getCompanySideBarInfo() }}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <image-uploader :number-of-files="1"
                                  :input-list="sideBarImages"
                                  @change="sideBarChanged">
                  </image-uploader>
                </div>
              </div>

            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;
            <l-button @click="$router.push('/companies/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import {Switch as LSwitch} from 'src/components/index'
import {ImageUploader} from 'src/components/index'
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    LSwitch,
    ImageUploader
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      companies: [],
      logoImages: [],
      sideBarImages: [],

      formData: {
        name: "",
        phone: "",
        head_office: "",
        address: "",
        logo: "",
        side_bar_image: "",
        is_active: true,
        parent_id: null
      },

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },

  created() {
    this.loader = this.$loading.show({
      container: this.$refs.companyForm
    });

    this.id = this.$route.params['id'];
    let companyId = this.id !== undefined ? this.id : "";

    this.axios.get("companies/builder?company_id=" + companyId).then((response) => {
      this.companies = response.data.companies;
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Company";
        this.getCompany();
      } else {
        this.editMode = false;
        this.formTitle = "Add Company";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    });

  },


  methods: {
    getCompany() {
      this.axios.get("companies/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.prepareLogoImage();
        this.prepareSideBarImage();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Company Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        let updateData = {...this.formData};
        this.$delete(updateData, 'password');
        request = this.axios.put("companies/update/" + this.id, updateData);
        successMessage = "Company Updated Successfully";
      } else {
        request = this.axios.post("companies/create", this.formData);
        successMessage = "Company Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/companies/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    },

    prepareLogoImage() {
      if (this.formData.logo) {
        this.logoImages = [{path: this.formData.logo}];
      } else {
        this.logoImages = [];
      }
    },

    logoChanged(images) {
      this.formData.logo = images.length > 0 ? images[0].path : null;
    },

    prepareSideBarImage() {
      if (this.formData.side_bar_image) {
        this.sideBarImages = [{
          path: this.formData.side_bar_image
        }];
      } else {
        this.sideBarImages = [];
      }
    },

    sideBarChanged(images) {
      this.formData.side_bar_image = images.length > 0 ? images[0].path : null;
    },

    getCompanyLogoInfo() {
      if(this.mediaInfo["company_logo"]) {
        return this.mediaInfo["company_logo"];
      } else {
        return "";
      }
    },
    getCompanySideBarInfo() {
      if(this.mediaInfo["company_side_bar"]) {
        return this.mediaInfo["company_side_bar"];
      } else {
        return "";
      }
    }
  }
}
</script>

<style>
</style>
